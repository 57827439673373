<template>
    <div class="wrapper">

        <div class="body" style="padding:0;">
            <Slider></Slider>
            <!-- End Of Page Header -->
            <div class="self-exclusion-wrapper">
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-dark">
                            <div class="self-exclusion">
                                <h3 class="text-dark">Exclusion from Betting</h3>
                                <p class="text-dark">
                                    This self exclusion page provides you with the option to take a
                                    break from gambling activities for a specific period of time.
                                    By utilising this feature, you can restrict your access to our
                                    platform preventing yourself from placing bets and engaging in
                                    gambling activities
                                </p>
                                <h6 class="text-dark">Your phone number is: +{{ myProfile.msisdn }}</h6>
                                <p></p>
                                <div class="select-period">
                                    <h6 class="text-dark">Select Period of exclusion</h6>
                                    <div class="cat-c">
                                        <select class="text-dark form-control"
                                            style="width: auto;border: solid black 1px;">
                                            <option value="select-period" selected>Select Period</option>
                                            <option value="1-month">1 Month</option>
                                            <option value="6-months">6 Months</option>
                                            <option value="12-months">12 Months</option>
                                            <option value="24-months">24 Months</option>
                                            <option value="36-months">36 Months</option>
                                            <option value="48-months">48 Months</option>
                                            <option value="60-months">60 Months</option>
                                        </select>
                                    </div>
                                    <br />
                                    <button type="button"
                                        class="btn btn-block btn-lg btn-danger mb-2 w-100 p-1">
                                        Exclude me from Betting
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Slider from '@/components/Slider.vue'

export default {
    name: 'SelfExclusion',
    components: {
        Slider,
    },
    data: function () {
        return {
            myProfile: this.getProfile(),
        }
    },
    mounted() {
        var vm = this;
        vm.myProfile = vm.getProfile();

        this.reloadProfile();

        if (!vm.myProfile) {
            this.$toast.open({
          message: 'Please login to proceed',
          type: 'error',
          position: 'top'
        });
            this.$router.push({ name: "login", params: {} });
            return;
        }

        setInterval(function () {
            vm.myProfile = vm.getProfile();
        }, 1000);
    }


};
</script>


<style scoped>
select.shadow-none {
    width: auto;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    font-size: 12px;
    color: #333;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg fill="%23333" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
}

select.shadow-none:focus {
    outline: none;
    border-color: #8faf37;
}

select.shadow-none option {
    padding: 10px;
    font-size: 11px;
    color: #333;
}
</style>